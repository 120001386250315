export function extractQueryParameters() {
    return new URLSearchParams(window.location.search)
}

export const GEOLOCATION_PERMISSION_PROMPT = 'prompt'
export const GEOLOCATION_PERMISSION_GRANTED = 'granted'
export const GEOLOCATION_PERMISSION_DENIED = 'denied'

export const AUTH_STEP = 0
export const MAIN_INFO_STEP = 1
export const ASK_FOR_GEOLOCATION_PERMISSIONS_STEP = 2
export const REGISTER_STEP = 3
export const SUCCESS_STEP = 4
export const GEOLOCATION_PERMISSIONS_DENIED_STEP = 5;

export function getLocation() {
    return new Promise((resolve, reject) => {
        navigator.geolocation.getCurrentPosition(
            (position) => {
                resolve({latitude: position.coords.latitude, longitude: position.coords.longitude})
            }, (error) => { reject(error) }
        )
    })
}

export async function register(token, occurrenceId, classroomCode, position, staticQr) {
    const requestOptions = {
        method: 'POST',
        headers: {"Content-Type": "application/json", "x-access-token": token},
        body: JSON.stringify({occurrenceId, classroomCode, position, staticQr})
    }

    const response = await fetch(`${process.env.VUE_APP_API_ROOT}/class-api/student/attendance/updateStudentAttendanceQR`, requestOptions)
    const json = await response.json()
    if (200 === response.status) {
        if (json.status) {
            return json.data[0]
        } else {
            throw {message: json.message}
        }
    } else {
        throw {message: json?.message ?? `Error http: ${response.status}`}
    }
}
