<template>

  <div class="heading-logo pb-3"><!-- heading-logo -->
    <div class="text-center">
      <img src="images/logotipo-unab.png" alt="Logotipo Universidad Andr&eacute;s bello">
    </div>
  </div><!-- /heading-logo -->

  <div class="panel border shadow-sm rounded-3 p-0"><!-- panel -->

      <div class="content-head px-4 pt-5 pb-4 text-center"><!-- content-head -->
        <h3 class="text-center pb-4">Ubicación</h3>
        <i class="fas fa-map-marker-alt pb-2 danger"></i>
      </div><!-- /content-head -->

      <div class="content-body px-4 pb-5 text-center"><!-- content-body -->
          <p class="pb-4">Tienes bloqueados los permisos de ubicación de tu dispositivo.</p>
          <p class="pb-5">Para conceder los permisos de ubicación es necesario que vayas a la configuración de tu dispositivo.</p>
        <div class="d-grid mt-5">
          <button class="btn btn-primary" @click="alreadyGranted">Ya he permitido usar mi ubicación</button>
        </div>
       <div class="pt-2">
         <a class="small btn-link" href="" @click.prevent="continueNoGeolocation">Continuar sin ubicación</a>
       </div>
         
      </div><!-- /content-body -->

  </div><!-- panel -->
</template>
<script>

import { /*MAIN_INFO_STEP,*/ REGISTER_STEP} from "@/common/utils";

export default {
  name: 'GeolocationPermissionsDenied',
  emits: ['next', 'back', 'error', 'loading', 'stopLoading'],
  props: {
    geoLocationPermissionStatus: String,
  },
  methods: {
    alreadyGranted() {
      // this.$emit('next', { goto: MAIN_INFO_STEP })
      window.location.reload();
    },
    continueNoGeolocation() {
      this.$emit('next', { goto: REGISTER_STEP})
    }
  },
}
</script>
