<template>

  <div class="heading-logo pb-3"><!-- heading-logo -->
    <div class="text-center">
      <img src="images/logotipo-unab.png" alt="Logotipo Universidad Andr&eacute;s bello">
    </div>
  </div><!-- /heading-logo -->

  <div class="panel border shadow-sm rounded-3 p-0"><!-- panel -->

    <div class="content-head px-4 pt-5 pb-4 text-center"><!-- content-head -->
      <h3 class="text-center pb-2">¡Listo! <span>Puedes cerrar esta ventana</span></h3>
      <i class="fas fa-paper-plane pb-2 success"></i>
      <div class="content-title">
        <p class="pb-3 text-center">Tus datos de registro de asistencia fueron enviados.</p>
      </div>
    </div><!-- /content-head -->

    <div class="content-body px-4 pb-5"><!-- content-body -->

      <h6 class="pb-2 ps-2">Res&uacute;men de datos enviados</h6>
      <ClassInfo :class2-register="class2Register"
                 :geo-location-permission-status="geoLocationPermissionStatus"

      />
    </div><!-- /content-body -->

  </div><!-- panel -->
</template>

<script>

import ClassInfo from "@/components/ClassInfo";
import {MAIN_INFO_STEP, register} from "@/common/utils";

export default {
  name: 'Success',
  emits: ['next', 'back', 'error', 'loading', 'stopLoading'],
  props: {
    geoLocationPermissionStatus: String,
    position: {latitude: null, longitude: null},
    class2Register: {
      occurrenceId: 0,
      courseName: '',
      campusLocation: '',
      profesorName: '',
      section: '',
      classDate: '',
      module: '',
      activityName: '',
      groupName: '',

      url: '',
    },
    token: String,
    occurrenceId: Number,
    classroomCode: String,
    staticQr: Boolean
  },
  methods: {
    mockRegister() {
      return new Promise((resolve) => {
        setTimeout(() => {
          resolve({ ok: true });
        }, 300);
      })
    },
    async doRegister() {
      this.$emit('loading')
      try {
        // const response = await this.mockRegister()
        const response = await register(this.token, this.occurrenceId, this.classroomCode, this.position, this.staticQr)
        if(!response.ok) {
          this.$emit('error', response.error)
        }
      } catch (e) {
        this.$emit('next',{ error: { message: 'Error al intentar llamar a la API de registro de asistencia'}, goto: MAIN_INFO_STEP})
      } finally {
        this.$emit('stopLoading')
      }
    }
  },
  mounted() {
    this.doRegister()
  },
  components: {
    ClassInfo
  }
}
</script>
