<template>
  <div class="heading-logo pb-3"><!-- heading-logo -->
    <div class="text-center">
      <img src="images/logotipo-unab.png" alt="Logotipo Universidad Andr&eacute;s bello">
    </div>
  </div><!-- /heading-logo -->

  <div class="panel border shadow-sm rounded-3 p-0"><!-- panel -->

    <div class="content-head px-4 pt-5 pb-4 text-center"><!-- content-head -->
      <h3 class="text-center pb-4">¡Ya casi estás listo!</h3>
      <div class="content-title">
        <p class="pb-3">Verifica los datos en pantalla y confirma para registrar tu asistencia.</p>
      </div>
    </div><!-- /content-head -->

    <div class="content-body px-4 pb-5"><!-- content-body -->
      <ClassInfo :class2-register="class2Register"
                 :geo-location-permission-status="geoLocationPermissionStatus"
                 :show-geolocation-permissions-change-link="true"
                 @next="$emit('next', $event)"
      />
      <div class="d-grid gap-2 mt-5">
        <button class="btn btn-primary" @click="next">Confirmar</button>
      </div>
    </div><!-- /content-body -->

  </div><!-- panel -->
</template>

<script>
import ClassInfo from "@/components/ClassInfo";
import { register } from "@/common/utils";

export default {
  name: 'Register',
  emits: ['next', 'back', 'error', 'loading', 'stopLoading'],
  props: {
    geoLocationPermissionStatus: String,
    position: {latitude: null, longitude: null},
    class2Register: {
      occurrenceId: 0,
      courseName: '',
      campusLocation: '',
      profesorName: '',
      section: '',
      classDate: '',
      module: '',
      activityName: '',
      groupName: '',

      url: '',
    },
    token: String,
    occurrenceId: Number,
    classroomCode: String,
    staticQr: Boolean
  },
  methods: {
    async next() {
      this.$emit('loading')
      try {
        // const response = await this.mockRegister()
        const response = await register(this.token, this.occurrenceId, this.classroomCode, this.position, this.staticQr)
        if(response.ok) {
          this.$emit('next', {})
        } else {
          this.$emit('error', response.error)
        }

      } catch (e) {
        this.$emit('error', { message: 'Error al intentar llamar a la API de registro de asistencia'})
      } finally {
        this.$emit('stopLoading')
      }
    },
    back() {
      this.$emit('back')
    }
  },
  components: {
    ClassInfo
  }
}
</script>
