<template>
  <div class="panel login border shadow-sm rounded-3 p-0 mt-5"><!-- panel -->

    <div class="panel-heading rounded-top p-4"><!-- panel-heading-->
      <div class="text-center">
        <img src="images/isotipo-unab.png" alt="Logotipo Universidad Andr&eacute;s bello">
      </div>
    </div><!-- panel-heading-->

    <div class="panel-body px-4 pb-5"><!-- panel-body-->
      <div class="text-center m-5">
        <h3>Bienvenido</h3>
        <p>Ingresa tus datos de acceso</p>
      </div>
      <form @submit.prevent="next">
        <div class="pb-4">
          <label for="username" class="form-label">Email</label>
          <input class="form-control" id="username" type="text" v-model="username">
        </div>
        <div class="pb-2">
          <label for="password" class="form-label">Contrase&ntilde;a</label>
          <input class="form-control" id="password" type="password" v-model="password">
        </div>
        <div class="d-grid mt-4">
          <button class="btn btn-primary" type="submit">Ingresar</button>
        </div>
      </form>
    </div><!-- /panel-body-->

  </div><!-- panel -->
</template>
<script>
import {GEOLOCATION_PERMISSION_PROMPT, MAIN_INFO_STEP, SUCCESS_STEP} from "@/common/utils";

export default {
  name: 'Auth',
  emits: ['next', 'back', 'error', 'loading', 'stopLoading'],
  props: {
    geoLocationPermissionStatus: String,
    permissionsApiAvailable: Boolean,
    occurrenceId: Number,
    classroomCode: String
  },
  data() {
    return {username: 'username', password: 'password'}
  },
  methods: {
    async next() {
      this.$emit('loading')
      try {

        const token = await this.auth();
        const userAndToken = await this.validate(token)
        const class2Register = await this.getStudentCurrentClassInfo(userAndToken.token, this.occurrenceId, this.classroomCode)

        /*const userAndToken = {token: "", user: {name: "Juan Pérez"}}
        const class2Register = {
          "occurrenceId": 6352054,
          "courseName": "UPL034 - Orientación educativa",
          "campusLocation": "UH-21 / Monterrico",
          "profesorName": "Francisco Véliz, Docente 1000, Docente 1",
          "section": "86890/86890",
          "classDate": "Jueves 03/02/2022",
          "module": "18:30 - 18:59",
          "activityName": "TPR",
          "groupName": "1"
        }*/


        if (!class2Register) {
          return this.$emit('error', {message: 'No se pueden obtener datos de la clase'})
        }
        let nextStep = MAIN_INFO_STEP
        if (this.permissionsApiAvailable) {
          /*if (GEOLOCATION_PERMISSION_GRANTED === this.geoLocationPermissionStatus) {
            nextStep = REGISTER_STEP
          } else if (GEOLOCATION_PERMISSION_DENIED === this.geoLocationPermissionStatus) {
            nextStep = GEOLOCATION_PERMISSIONS_DENIED_STEP
          }*/
          if(GEOLOCATION_PERMISSION_PROMPT !== this.geoLocationPermissionStatus) {
            nextStep = SUCCESS_STEP
          }
        }
        this.$emit('next', {goto: nextStep, token: userAndToken.token, user: userAndToken.user, class2Register, occurrenceId: class2Register.occurrenceId})
      } catch (e) {
        this.$emit('error', e)
      } finally {
        this.$emit('stopLoading')
      }
    },
    async auth() {
      const requestOptions = {
        method: 'POST',
        headers: {"Content-Type": "application/json"},
        body: JSON.stringify({name: this.username, password: this.password, type: 'WEB'})
      }
      const response = await fetch(`${process.env.VUE_APP_API_ROOT}/user-api`, requestOptions)
      if (200 === response.status) {
        const json = await response.json()
        if (json.status) {
          return json.data
        } else {
          throw {message: 'Credenciales incorrectas'}
        }
      } else {
        throw {message: 'Error al intentar autenticación'}
      }
    },

    async validate(token) {
      const requestOptions = {
        method: 'POST',
        headers: {"Content-Type": "application/json", "x-access-token": token}
      }
      const response = await fetch(`${process.env.VUE_APP_API_ROOT}/user-api/validate`, requestOptions)
      const json = await response.json()
      if (200 === response.status) {
        if (json.status) {
          return {user: {name: json.data.user.name}, token: json.data.token}
        } else {
          throw {message: json.message}
        }
      } else {
        throw {message: json?.message ?? `Error http: ${response.status}`}
      }
    },

    async getStudentCurrentClassInfo(token, occurrenceId, classroomCode) {
      const requestOptions = {
        method: 'POST',
        headers: {"Content-Type": "application/json", "x-access-token": token},
        body: JSON.stringify({occurrenceId, classroomCode})
      }

      const response = await fetch(`${process.env.VUE_APP_API_ROOT}/class-api/student/classes/getStudentCurrentClassInfo`, requestOptions)
      const json = await response.json()
      if (200 === response.status) {
        if (json.status) {
          return json.data[0]
        } else {
          throw {message: json.message}
        }
      } else {
        throw {message: json?.message ?? `Error http: ${response.status}`}
      }
    }

  }
}
</script>
