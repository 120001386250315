<template>
    <div class="heading-logo pb-3"><!-- heading-logo -->
        <div class="text-center">
          <img src="images/logotipo-unab.png" alt="Logotipo Universidad Andr&eacute;s bello">
        </div>
    </div><!-- /heading-logo -->

    <div class="panel border shadow-sm rounded-3 p-0"><!-- panel -->

      <div class="content-head px-4 pt-5 pb-4 text-center"><!-- content-head -->
        <h3 class="text-center pb-4">¡Hola {{ user.name }}!</h3>
        <div class="content-title pb-3">
          <p class="pb-3">Estas registrando la asistencia para la clase: </p>
          <p class="className">{{ class2Register.courseName }}</p>
        </div>
      </div><!-- /content-head -->

      <div class="content-body px-4 pb-5"><!-- content-body -->

        <ClassInfo :class2-register="class2Register"
                   :geo-location-permission-status="geoLocationPermissionStatus"
                   :hide-course-name="true"
        />

        <div class="pt-3">
          <div v-if="!deniedGeolocation && !grantedGeolocation" class="info-card ps-2 success">
            <i class="fas fa-map-marker-alt"></i>
            <p class="ps-3">Para ayudar a determinar tu asistencia presencial necesitamos que nos permitas usar la ubicación de tu dispositivo.</p>
          </div>
          <div v-if="deniedGeolocation" class="info-card ps-2 danger">
            <i class="fas fa-map-marker-alt"></i>
            <p class="ps-3">Tienes bloqueado el permiso de ubicación de tu dispositivo. Para concederlos es necesario que vayas a
            la configuración de tu dispositivo.</p>
          </div>
        </div>

        <div class="d-grid mt-5">
          <button class="btn btn-primary" @click="next">Enviar datos de registro</button>
        </div>
      </div><!-- /content-body -->
      
    </div><!-- panel -->
</template>
<script>
import ClassInfo from "@/components/ClassInfo";
import {
  ASK_FOR_GEOLOCATION_PERMISSIONS_STEP,
  GEOLOCATION_PERMISSION_DENIED, GEOLOCATION_PERMISSION_GRANTED,
  GEOLOCATION_PERMISSION_PROMPT,
  GEOLOCATION_PERMISSIONS_DENIED_STEP, getLocation,
  MAIN_INFO_STEP,
  REGISTER_STEP,
  SUCCESS_STEP
} from "@/common/utils";

export default {
  name: 'MainInfoDisplay',
  emits: ['next', 'back', 'error', 'loading', 'stopLoading'],
  props: {
    geoLocationPermissionStatus: String,
    permissionsApiAvailable: Boolean,
    user: {
      name: '',
      lastname: ''
    },
    position: {latitude: null, longitude: null},
    class2Register: {
      occurrenceId: 0,
      courseName: '',
      campusLocation: '',
      profesorName: '',
      section: '',
      classDate: '',
      module: '',
      activityName: '',
      groupName: '',

      url: '',
    },
  },
  methods: {
    nextWithPermissionsApiAvailable: function () {
      let nextStep = REGISTER_STEP;
      if (GEOLOCATION_PERMISSION_PROMPT === this.geoLocationPermissionStatus) {
        nextStep = ASK_FOR_GEOLOCATION_PERMISSIONS_STEP
      } else if (GEOLOCATION_PERMISSION_DENIED === this.geoLocationPermissionStatus) {
        nextStep = GEOLOCATION_PERMISSIONS_DENIED_STEP
      }
      this.$emit('next', {goto: nextStep})
    },
    nextUsingShortFlow: async function () {
      let goto = SUCCESS_STEP
      if (GEOLOCATION_PERMISSION_DENIED !== this.geoLocationPermissionStatus) {
        let position = this.position
        let geoLocationPermissionStatus = this.geoLocationPermissionStatus
        if(! (position?.latitude && position.longitude )) {
          try {
            position = await getLocation()
            geoLocationPermissionStatus = GEOLOCATION_PERMISSION_GRANTED
          } catch (error) {
            if (error.PERMISSION_DENIED === error.code) {
              geoLocationPermissionStatus = GEOLOCATION_PERMISSION_DENIED
              goto = MAIN_INFO_STEP
            } else {
              this.$emit('error', error)
            }
          }
        }
        this.$emit('next', { position, goto, geoLocationPermissionStatus})
      } else {
        this.$emit('next', { goto })
      }
    }, next() {
      /*if (this.permissionsApiAvailable) {
        this.nextWithPermissionsApiAvailable();
      } else {*/
        this.nextUsingShortFlow();
      // }
    }
  },
  computed: {
    deniedGeolocation() {
      return GEOLOCATION_PERMISSION_DENIED === this.geoLocationPermissionStatus
    },
    grantedGeolocation() {
      return GEOLOCATION_PERMISSION_GRANTED === this.geoLocationPermissionStatus
    }
  },
  components: {
    ClassInfo
  }
}
</script>
